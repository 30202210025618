var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function removeFolderById(folder, idToRemove) {
    return folder
        .map(function (currentFolder) {
        var _a, _b;
        var updatedFolder = __assign({}, currentFolder);
        if (updatedFolder._id === idToRemove)
            return null;
        if (((_a = updatedFolder.subFolder) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            updatedFolder.subFolder = removeFolderById(updatedFolder.subFolder, idToRemove);
        }
        updatedFolder.subFolder = (_b = updatedFolder.subFolder) === null || _b === void 0 ? void 0 : _b.filter(function (subFolder) { return subFolder !== null && subFolder._id !== idToRemove; });
        return updatedFolder;
    })
        .filter(function (folderItem) { return folderItem !== null; });
}
export function addObjectToSubFolder(parentId, newObj, folders) {
    if (folders === void 0) { folders = []; }
    if (parentId === null) {
        return folders.map(function (folder) { return (folder._id === newObj._id ? newObj : folder); });
    }
    return folders.map(function (folder) {
        if (folder._id === parentId) {
            return __assign(__assign({}, folder), { subFolder: folder.subFolder.map(function (elt) { return (elt._id === newObj._id ? newObj : elt); }) });
        }
        return __assign(__assign({}, folder), { subFolder: addObjectToSubFolder(parentId, newObj, folder.subFolder || []) });
    });
}
export function createNewFolder(parentId, newFolder, folders) {
    if (parentId === null)
        return __spreadArray(__spreadArray([], folders, true), [newFolder], false);
    return folders.map(function (folder) {
        if (folder._id === parentId) {
            return __assign(__assign({}, folder), { subFolder: __spreadArray(__spreadArray([], (folder.subFolder || []), true), [newFolder], false) });
        }
        return __assign(__assign({}, folder), { subFolder: createNewFolder(parentId, newFolder, folder.subFolder || []) });
    });
}
export function removeFileFromFolderDocs(folder, fileId) {
    return folder.map(function (currentFolder) {
        var updatedFolder = __assign({}, currentFolder);
        if (updatedFolder.subFolder.length > 0) {
            updatedFolder.subFolder = removeFileFromFolderDocs(updatedFolder.subFolder, fileId);
        }
        updatedFolder.files = updatedFolder.files.filter(function (file) { return file._id !== fileId; });
        return updatedFolder;
    });
}
export function addObjectTFiles(folderId, newObj, folders) {
    if (folders === void 0) { folders = []; }
    return folders.map(function (folder) {
        if (folder._id === folderId) {
            return __assign(__assign({}, folder), { files: __spreadArray(__spreadArray([], (folder.files || []), true), [newObj], false) });
        }
        return __assign(__assign({}, folder), { subFolder: addObjectTFiles(folderId, newObj, folder.subFolder || []) });
    });
}
export function findFolderById(folder, idToFind) {
    var foundFolders = folder.map(function (currentFolder) {
        if (currentFolder._id === idToFind) {
            return currentFolder;
        }
        if (currentFolder.subFolder && currentFolder.subFolder.length > 0) {
            return findFolderById(currentFolder.subFolder, idToFind);
        }
        return null;
    });
    var foundFolder = foundFolders.flat().find(function (elt) { return elt !== null; });
    return foundFolder || null;
}
